var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-content"},[_c('div',{staticClass:"container-fluid"},[_c('Breadcrumb',{attrs:{"pgTitle":_vm.$t('nav.appointmentConsultations'),"pgIcon":'bx bx-buildings',"refs":_vm.refs,"addNew":_vm.addNew}}),_c('DataTable',{attrs:{"refs":_vm.refs,"colspan":9,"locale":_vm.$i18n.locale,"hasSearchFilter":true,"hasTimeFilter":true,"hasPeriodFilter":true,"hasDateFilter":true,"hasStatusApproved":true,"hasStatusFilter":true,"hasLecturerFilter":true,"hasHashId":true,"hasMultiSelect":false,"hasBulkAction":true,"HasExport":true,"hasShowEntries":true,"lblSearch":_vm.$t('app.search_by_name'),"fields":{
                'id': 'id',
                'username': 'username',
                'period': 'period',
                'price': 'price',
                'time': 'time',
                'date': 'date',
                'paid': 'paid',
                'status': 'status'
            },"hasUser":true,"image":_vm.$t('view.user'),"image_style":'width: 40px;height: 40px;border-radius: 50%',"price":_vm.$t('app.price'),"hasDate":true,"hasPaid":true,"hasTime":true,"hasPeriod":true,"hasPrice":true,"hasStatus":true,"hasNoEdit":true,"hasAccept":true,"hasDecline":true,"hasDelayed":true,"hasApproved":true,"hasActions":true,"hasNoDelete":true,"actionable":true,"sorDataTable":true},on:{"hasAddNewPermission":_vm.handlePermissoinChange}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }